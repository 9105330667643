import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import callApi from '../../services/api';
import { FormInput, ButtonContainer, Button } from '../../components/home/v2/styledComponents';
import { mobileThresholdPixels, colors } from '../../components/styledComponents';
import { isEmailValid } from '../../services/checkingFormat';
import routesMap from '../../Routes';
import { pushToLayer } from '../../services/googleTagManager';

const Text = styled.p`
  color: ${props => props.error ? colors.error : colors.navy};
  text-align: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
`;

const StyledFormInput = styled(FormInput)`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class Register extends Component {
  constructor(props) {
    super(props);
    const { customer, promoCode } = props;
    this.state = {
      firstname: customer.firstname,
      lastname: customer.lastname,
      email: customer.email,
      password: customer.password,
      phone: customer.phone || '',
      promoCode,
      errorMsg: '',
    };
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.submitRegister = this.submitRegister.bind(this);
  }

  onChangeEvent(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  checkError() {
    const { firstname, lastname, email, phone } = this.state;
    if (!firstname || !lastname || !email || !phone) {
      this.setState({ errorMsg: 'Le prénom, nom, email et téléphone doivent être renseignés.' });
      return true;
    } else if (email && !isEmailValid(email)) {
      this.setState({ errorMsg: 'Le format de l\'email est invalide.' });
      return true;
    }
    return false;
  }

  submitRegister() {
    const { isFacebook, promoCode } = this.props;
    if (this.checkError()) return;
    const endpoint = isFacebook ? 'customers/me' : 'register';
    const method = isFacebook ? 'PUT' : 'POST';

    callApi(endpoint, method, { ...this.state })
      .then(({ customer }) => {
        navigate(routesMap.Step1.url, { state: { promoCode, customer } });
        pushToLayer({
          event: 'Order Funnel Started',
          from: window.location.pathname,
          from_interaction: 'Referral',
          funnel_type: 'standard',
        });
      })
      .catch((err) => {
        let errorMsg = '';
        if (err.errors && err.errors.email) {
          errorMsg = 'Cet email est déjà associé à un compte';
        } else {
          errorMsg = (err.errors && err.errors.existingOrders)
            ? 'Cet email est déjà associé à un compte. Le code parrainage ne fonctionne que pour la 1ère commande.'
            : 'Oups, quelque chose s‘est mal passé';
        }
        this.setState({ errorMsg });
      });
  }

  render() {
    const { firstname, lastname, phone, email, promoCode, errorMsg } = this.state;
    const { isFacebook } = this.props;
    return (
      <div>
        <Text>Inscription sur Tilli</Text>
        <Text>Votre code parrain a été ajouté ! Bénéficiez de 5€ de réduction sur votre première commande.</Text>
        <FormContainer>
          <StyledFormInput
            name={'firstname'}
            placeholder={'Prénom'}
            value={firstname}
            onChange={this.onChangeEvent}
          />
          <StyledFormInput
            name={'lastname'}
            placeholder={'Nom'}
            value={lastname}
            onChange={this.onChangeEvent}
          />
          <StyledFormInput
            name={'phone'}
            placeholder={'Téléphone'}
            value={phone}
            onChange={this.onChangeEvent}
          />
          {!isFacebook && (
            <StyledFormInput
              name={'email'}
              placeholder={'Email'}
              value={email}
              onChange={this.onChangeEvent}
            />
          )}
          <StyledFormInput
            name={'promoCode'}
            placeholder={'Code promo'}
            defaultValue={promoCode}
            disabled
          />
          <StyledButtonContainer>
            <Button navy onClick={this.submitRegister}>
              {'Finaliser l\'inscription'}
            </Button>
            {errorMsg !== '' && <Text error>{errorMsg}</Text>}
          </StyledButtonContainer>
        </FormContainer>
      </div>
    );
  }
}

Register.propTypes = {
  customer: PropTypes.shape({}).isRequired,
  promoCode: PropTypes.string.isRequired,
  isFacebook: PropTypes.bool.isRequired,
};

export default Register;
