import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FacebookLogin from 'react-facebook-login';

import callApi from '../../services/api';
import { trackUser } from '../../services/analytics';
import Layout from '../../layouts/index';
import {
  A,
  Container,
  Content,
  FormInput,
  ButtonContainer,
  Button,
} from '../../components/home/v2/styledComponents';
import Dots from '../../components/home/v2/Dots';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import { mobileThresholdPixels, colors, fontSizes } from '../../components/styledComponents';
import Register from './Register';
import { isEmailValid } from '../../services/checkingFormat';

const Text = styled.p`
  color: ${props => props.error ? colors.error : colors.navy};
  text-align: center;
  padding: 0px 20px;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: ${fontSizes.m};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    font-size: ${fontSizes.s};
  }
`;

const StyledContent = styled(Content)`
  align-items: center;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  width: 90vw;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
`;

const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFormInput = styled(FormInput)`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const StyledA = styled(A)`
  font-size: ${fontSizes.m};
`;

class InvitePage extends Component {
  constructor() {
    super();
    this.state = {
      customer: null,
      email: '',
      password: '',
      errorMsg: '',
      isFacebook: false,
    };
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.checkError = this.checkError.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChangeEvent(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  loginWithFacebook({ accessToken }) {
    callApi('login', 'POST', { fbAccessToken: accessToken }).then(({ customer }) => {
      trackUser(customer);
      this.setState({
        customer,
        isFacebook: true,
      });
    }).catch(() => {});
  }

  checkError() {
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ errorMsg: 'L\'email et le mot de passe doivent être renseignés' });
      return true;
    } else if (email && !isEmailValid(email)) {
      this.setState({ errorMsg: 'Le format de l\'email est invalide' });
      return true;
    }
    return false;
  }

  submit() {
    if (!this.checkError()) {
      this.setState({
        customer: {
          firstname: '',
          lastname: '',
          email: this.state.email,
          password: this.state.password,
        },
      });
    }
  }

  renderWithCustomer() {
    const { email, password, errorMsg } = this.state;
    const { location: { search } } = this.props;
    const promoCode = search.slice(3);
    return (
      <StyledContent>
        <Text>Inscription avec le code promo {promoCode}</Text>
        <FacebookLogin
          appId="258420877937846"
          autoLoad={false}
          fields="first_name,last_name,email"
          textButton="continuer avec Facebook"
          cssClass="kep-login-facebook facebook_button"
          isMobile
          disableMobileRedirect
          callback={({ accessToken }) => this.loginWithFacebook({ accessToken })}
        />
        <Dots />
        <FormContainer>
          <StyledFormInput
            name={'email'}
            placeholder={'Email'}
            value={email}
            onChange={this.onChangeEvent}
          />
          <StyledFormInput
            name={'password'}
            type={'password'}
            placeholder={'Mot de passe'}
            value={password}
            onChange={this.onChangeEvent}
          />
          <StyledButtonContainer>
            <Button navy onClick={this.submit}>
              {'S\'inscrire'}
            </Button>
            {errorMsg !== '' && <Text error>{errorMsg}</Text>}
            <StyledA>{'J\'ai déjà un compte'}</StyledA>
          </StyledButtonContainer>
        </FormContainer>
      </StyledContent>
    );
  }

  render() {
    const { customer, isFacebook } = this.state;
    const { location: { search } } = this.props;
    const promoCode = search.slice(3);
    return (
      <div>
        <Layout routeSlug="Invite">
          <Container>
            <StyledContent full>
              <MenuBar />
              {customer === null && this.renderWithCustomer()}
              {customer !== null && <Register customer={customer} promoCode={promoCode} isFacebook={isFacebook} />}
            </StyledContent>
          </Container>
          <Footer />
        </Layout>
      </div>
    );
  }
}
InvitePage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default InvitePage;
